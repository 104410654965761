import React from "react";
import styled, { css } from "styled-components";

import Tooltip from "../tooltip/Tooltip";

const Container = styled.div`
    display: flex;
    max-width: 800px;
    align-items: center;
    justify-content: center;

    @media (max-width: 640px) {
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
`

const BubbleContainer = styled.div`
    display: flex;
    margin: 25px;
    flex-direction: column;
    width: 160px;
    height: 160px;
    padding: 20px;
    background-color: #afafaf;
    border: 2px #a22206 solid;
    border-radius: 50%; 
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: all .2s ease-in-out;
    text-align: center;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

    :hover {
        transform: scale(1.2);
    }

    @media (hover: none) {
        transition: none;

        :hover {
            transform: none;
        }
    }

    ${props => props.active && css`
        transition: none;

        :hover {
            transform: none;
        }
    `}
`

const Title = styled.h4`
    margin: 0;
    padding: 0;
`

const P = styled.p`
    margin: 0;
    line-height: 18px;
`

const Project = ({ title, domain, description, onClickHanlder, onCloseHandler, active }) => {
    return (
        <Container>
            <BubbleContainer active={active} onClick={onClickHanlder} className={ active !== undefined && !active ? "blur-effect" : "" }>
                <Title>{title}</Title>
                <P>{domain}</P>
            </BubbleContainer>
            { active ? <Tooltip title={title} description={description} onCloseHandler={onCloseHandler} /> : null }
        </Container>
        
    );
}

export default Project;