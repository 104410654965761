import React from "react";
import { graphql } from "gatsby";
import { I18n } from "react-i18next";
import { withI18next } from "gatsby-plugin-i18next";
import smoothscroll from "smoothscroll-polyfill";

import App from "../components/App";

if (typeof window !== `undefined`) {
    smoothscroll.polyfill();
}

const IndexPage = () => (
    <I18n>
        { t => (
            <App />
        )}
    </I18n>
);

export default withI18next()(IndexPage);

export const query = graphql`
    query($lng: String!) {
        locales: allLocale(filter: { lng: {eq: $lng }, ns: { eq: "messages" } }) {
            ...TranslationFragment
        }
    }
`;
