import React from "react";
import styled, { css } from "styled-components";

const StyledLink = styled.li`
    padding: 10px 15px;
    margin: 0;
    cursor: pointer;

    @media (max-width: 640px) {
        padding: 5px 10px;
    }
`

const A = styled.a`
    ${props => props.hoverColor && css`
        &:hover {
            color: ${props.hoverColor}
        }
    `}

    @media (max-width: 640px) {
        font-size: 20px;
    }
`

const Link = ({ text, link, hoverColor, onClick }) => {
    return (
        <StyledLink>
            <A hoverColor={hoverColor} href={link} onClick={onClick}>{text}</A>
        </StyledLink>
    );
}

export default Link;