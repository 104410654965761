import React from "react";
import styled from "styled-components";

const LogoContainer = styled.div`
    flex: 1;
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    min-width: 320px;
    margin: 0;
    padding: 0;
`

const Img = styled.img`
    margin: auto;
    padding: 0;
    width: 50%;
`

const Logo = ({ logo }) => {
    return (
        <LogoContainer>
            <Img src={logo} alt="logo" />
        </LogoContainer>
    )
}

export default Logo;