import React from "react";
import Skill from "./Skill";

import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
`

const Skills = ({ title, skills }) => {
    return (
        <Container>
            <h3>{title}</h3>
            {skills.map((skill, index) => <Skill key={index} title={skill.title} percentage={skill.percentage} color={skill.color} />)}
        </Container>
    );
}

export default Skills;