import React from "react";
import styled from "styled-components";
import close from "../../../static/svgs/close.svg";

const TooltipContainer = styled.div`
    display: block;
    max-width: 400px;
    min-height: 160px;
    border: 2px #a22206 solid;
    border-radius: 5px;
    padding: 20px;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Title = styled.h4`
    margin: 0;
    padding: 0;
`

const P = styled.p`
    margin: 10px 0 0 0;
    line-height: 1.2;
    text-align: left;
`

const CloseIcon = styled.div`
    width: 20px;
    height: 20px;
`

const Tooltip = ({ title, description, onCloseHandler }) => {
    return (
        <TooltipContainer>
                <TitleContainer>
                    <Title>{title}</Title>
                    <CloseIcon onClick={onCloseHandler} ><img src={close} alt="close" /></CloseIcon>
                </TitleContainer>
                <P>{description}</P>
        </TooltipContainer>
        
    );
}

export default Tooltip;