import React from "react";
import styled from "styled-components";

import { Link } from "gatsby";

const StyledFooter = styled.footer`
    background-color: #222222;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    a {
        color: #ffffff;
        text-decoration: none;
        font-size: 11px;
        font-weight: normal;
    }
`

const Span = styled.span`
    font-size: 11px;
    text-align: center;
    padding: 10px 0;
    align-self: center;

    :after {
        content: "|";
        display: inline-block;
        padding: 0 1rem 0 1.2rem;
        color: rgba(255, 255, 255, 0.1);
    }

    :last-child:after {
        content: "";
    }
`

const Footer = ({ footerLinks }) => {
    return (
        <StyledFooter>
            <Span>{`© Copyright Narmocode - Luka Popovic ${new Date().getFullYear()}`}</Span>
            {footerLinks.map((footerLink) => <Span key={footerLink.text}><Link to={footerLink.link}>{footerLink.text}</Link></Span>)}
        </StyledFooter>
    )
}

export default Footer;