import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0;
    font-size: 16px;
    
`

const StatusBarContainer = styled.div`
    background-color: #ffffff;
    height: 18px;
    min-width: 150px;
    border: #afafaf 1px solid;
    border-radius: 2px;
    margin-right: 10px;
    flex-grow: 1;
`

const StatusBar = styled.div`
    margin: 2px;
    border-radius: 2px;
    height: 12px;
    ${props => props.color && css`
        background-color: ${props.color};
    `}
    ${props => props.percentage && css`
        width: ${props.percentage}%;
    `}
`

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 2;
`

const Span = styled.span`
    margin: 0;
    flex-grow: 1;
    line-height: 18px;
    min-width: 220px;
`

const PercentageContainer = styled.span`
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
`

const Skill = ({ title, percentage, color }) => {
    return (
        <Container>
            <Span>{title}</Span>
            <Content>
                <StatusBarContainer><StatusBar color={color} percentage={percentage} /></StatusBarContainer>
                <PercentageContainer>{`${percentage}/100`}</PercentageContainer>
            </Content>
        </Container>
    );
}

export default Skill;