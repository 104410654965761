import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { Link as GLink } from "gatsby";
import Link from "../link/Link";

const Nav = styled.nav`
    z-index: 2;
    position: fixed;
    display: flex;
    justify-content: space-between;
    min-height: 45px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #afafaf;

    @media (max-width: 640px) {
        flex-direction: column;
    }
`

const Navigation = styled.ul`
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    animation: fadein 1s;
    
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @media (max-width: 640px) {
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding-bottom: 10px;
    }
`

const SmallLogo = styled.div`
    height: 45px;
    min-height: 45px;
    min-width: 45px;
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Img = styled.img`
    width: 45px;
    position: relative;
    animation: fadein 1s;
    margin: 0;
    
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`

const A = styled.a`
    margin: auto;
`

const Hamburger = styled.button`
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 0 12px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
    animation: fadein 1s;
    
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`

const HamburgerBox = styled.span`
    position: relative;
    display: block;
    width: 30px;
    height: 24px;
`

const HamburgerInner = styled.span`
    position: absolute;
    left: 0;
    width: 30px;
    height: 4px;
    transition-timing-function: cubic-bezier(.19,1,.22,1);
    transition-duration: .2s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #a22206;
    top: 50%;
    display: block;
    margin-top: -2px;

    ${props => props.open && css`
        transition-timing-function: cubic-bezier(.19,1,.22,1);
        transform: rotate(765deg);
        background-color: #a22206;
    `}

    &:before {
        position: absolute;
        width: 30px;
        height: 4px;
        transition-timing-function: cubic-bezier(.19,1,.22,1);
        transition-duration: .2s;
        transition-property: transform;
        border-radius: 4px;
        background-color: #a22206;
        display: block;
        content: "";
        top: -10px;
        transition-property: top,opacity;
        transition-delay: .1s;
        transition-timing-function: linear;
        transition-duration: 0s;

        ${props => props.open && css`
            top: 0;
            opacity: 0;
            transition-delay: 0s;
        `}
    }

    &:after {
        position: absolute;
        width: 30px;
        height: 4px;
        transition-timing-function: cubic-bezier(.19,1,.22,1);
        transition-duration: .2s;
        transition-property: transform;
        border-radius: 4px;
        background-color: #a22206;
        display: block;
        content: "";
        bottom: -10px;
        transition-property: bottom,transform;
        transition-delay: .1s;
        transition-timing-function: linear;
        transition-duration: 0s;

        ${props => props.open && css`
            bottom: 0;
            transform: rotate(90deg);
            transition-delay: 0s;
        `}
        
    }
`

const LanguageSwitcher = styled.li`
    padding: 10px 15px;
    margin: 0;
    margin-top: 3px;
`

const languages = { en: "/en", de: "/de" };
const flags = { en: "🇺🇸", de: "🇩🇪" };

const Menu = ({ menuLinks, smallLogo }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const languageSwitcherObject = {
        link: window.location.pathname.includes(languages.en) ? languages.de : languages.en,
        flag: window.location.pathname.includes(languages.en) ? flags.de : flags.en
    }

    const onLinkClicked = (event, link) => {
        event.preventDefault();
        document.querySelector(link).scrollIntoView({ behavior: "smooth" });
        // window.location.hash = link;
        setOpen(false);
    }

    return (
        <Nav>
            <LogoContainer>
                <SmallLogo><A href="#"><Img src={smallLogo} alt="small_logo"></Img></A></SmallLogo>
                { width < 640 ?
                    <Hamburger type="button" onClick={() => setOpen(!open)}>
                        <HamburgerBox>
                            <HamburgerInner open={open}></HamburgerInner>
                        </HamburgerBox>
                    </Hamburger>
                : null }
            </LogoContainer>
            { open || width > 640  ? <Navigation>
                { menuLinks.map((menuLink => <Link onClick={(event) => onLinkClicked(event, menuLink.link)} text={menuLink.text} key={menuLink.text} />)) }
                <LanguageSwitcher><GLink to={languageSwitcherObject.link}>{languageSwitcherObject.flag}</GLink></LanguageSwitcher>
            </Navigation> : null }
        </Nav>
    )
}

export default Menu;