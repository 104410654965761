import React, { useState, useEffect, useRef } from "react";
import Project from "./Project";

import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
`
const ProjectContainer = styled.div`
    z-index: 1;
`

const Line = styled.div`
    position: absolute;
    background-color: #a22206;
    width: 2px;
    height: 100%;
    z-index: 0;

    ${props => props.height && css`
        height: ${props.height - 100}px;
    `}
`

const Projects = ({ projects }) => {
    const node = useRef();
    const container = useRef();
    const [clickedIndex, setClickedIndex] =  useState(-1);
    const [containerHeight, setContainerHeight] = useState(0);

    const onClickHanlder = (event, index) => {
        event.stopPropagation();
        if (clickedIndex !== index) {
            setClickedIndex(index);
            window.scroll({ top: event.currentTarget.offsetTop - 100, left: 0, behavior: "smooth"});
        }
    }

    const handleCloseButtonClicked = () => {
        setClickedIndex(-1);
    }

    useEffect(() => {
        setContainerHeight(container.current.clientHeight);
    }, [containerHeight])
    

    return (
        <Container ref={container}>
            { clickedIndex === -1 ? <Line  height={containerHeight}></Line> : null }
            {projects.map((project, index) => <ProjectContainer key={index} ref={node}>
                <Project
                    title={project.title}
                    domain={project.domain}
                    description={project.description}
                    onClickHanlder={ (event) => onClickHanlder(event, index)}
                    onCloseHandler={ handleCloseButtonClicked }
                    active={clickedIndex === -1 ? undefined : clickedIndex === index}
                />
            </ProjectContainer>)}
        </Container>
    );
}

export default Projects;