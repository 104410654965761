import React from "react";
import styled from "styled-components";

const StyledHeader = styled.header`
    padding: 20px 0;
    display: flex;
    height: 100vh;
    background-color: #afafaf;
    width: 100%;
    flex-wrap: wrap;
`

const Header = ({ children }) => {
    return (
        <StyledHeader id="home">
            { children }
        </StyledHeader>
    )
}

export default Header;