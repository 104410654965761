import React from "react";
import styled from "styled-components";

const AvatarContainer = styled.div`
    flex: 1;
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
`

const Img = styled.img`
    margin: auto;
    padding: 0;
    border: 2px #4d4a50 solid;
    border-radius: 50%;
    width: 50%;

    @media (max-width: 640px) {
        width: 45%;
    }
`

const Avatar = ({ avatarImage }) => {
    return (
        <AvatarContainer>
            <Img src={avatarImage} alt="avatar" />
        </AvatarContainer>
    )
}

export default Avatar;