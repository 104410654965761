import React from "react";
import styled from "styled-components";

//Images
import logo from "../../static/logo/logo.png";
import smallLogo from "../../static/logo/logo_small.png"
import avatarImage from "../../static/images/lukabw.jpg";
import who from "../../static/images/who.jpg";
import what from "../../static/images/what.jpg";
import how from "../../static/images/how.jpg";
import contact from "../../static/images/contact.jpg";

//Png and Svg Icons
import mail from "../../static/svgs/mail.svg"
import facebook from "../../static/pngs/facebook.png"
import linkedin from "../../static/pngs/linkedin.png"
import twitter from "../../static/pngs/twitter.png"
import xing from "../../static/pngs/xing.png"
import arrow from "../../static/pngs/arrow.png";

import { translate } from 'react-i18next';
import { Head } from 'gatsby-plugin-i18next';

import SpinnerContainer from "./spinner/SpinnerContainer";
import Header from "./header/Header";
import Logo from "./logo/Logo";
import Footer from "./footer/Footer";
import Menu from "./menu/Menu";
import Section from "./section/Section";
import Avatar from "./avatar/Avatar";
import Skills from "./skills/Skills";
import Projects from "./projects/Projects";

const Container = styled.div`
    margin: 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
`

const WelcomeContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;
`

const Welcome = styled.div`
    width: 60%;
`

const IconsContainer = styled.div`
    margin-top: 250px;
    height: auto;
    text-align: center;
`

const Icons = styled.ul`
    overflow: auto;
    padding: 0;
    margin: 0;
`

const Icon =  styled.li`
    padding: 10px;
    display: inline-block;
    text-align: center;
    width: 70px;
    opacity: .5;

    &:hover {
        opacity: 1;
        color: #ffffff;
    }
`

const ContactIcon = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    width: 100%;
`

const MailLink = styled.a`
    font-size: 22px;
    font-weight: bold;
    color: #000000;
`

const Img = styled.img`
    width: 40px;
    margin: 0;
`

const H3 = styled.h3`
    margin: 10px 0 0;
    text-align: center;
`

const P = styled.p`
    text-align: center;
`

const GoToTop = styled.div`
    display: none;
    background-color: transparent;
    opacity: .8;
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 40px;
    height: 40px;

    @media (max-width: 640px) {
        display: block;
        cursor: pointer;
    }
`

class App extends React.Component {
    state = {
        showNavigation: false,
        showTopButton: false,
        loading: true
    }

    componentDidMount() {
        window.onscroll = () => {
            this.setState({
                showNavigation: window.pageYOffset > (window.innerHeight - 60),
                showTopButton: window.pageYOffset > (window.innerHeight + 200)
            });
        };

        this.images.forEach((image, index) => {
            let preloadedImage = new Image();
            preloadedImage.src = image.startsWith("/") ? window.location.origin + image : image;
            preloadedImage.onload = () => index + 1 === this.images.length && this.setState({ loading: false });
        });
    }

    images = [
        logo,
        smallLogo,
        avatarImage,
        who,
        what,
        how,
        contact
    ]
    
    links = [
        {
            text: this.props.t('links.home'),
            link: "#home"
        },
        {
            text: this.props.t('links.about'),
            link: "#about"
        },
        {
            text: this.props.t('links.skills'),
            link: "#skills"
        },
        {
            text: this.props.t('links.projects'),
            link: "#projects"
        },
        {
            text: this.props.t('links.contact'),
            link: "#contact"
        }
    ];

    footerLinks = [
        {
            text: this.props.t('footer.links.privacy'),
            link: "/privacy"
        },
        {
            text: this.props.t('footer.links.disclaimer'),
            link: "/disclaimer"
        },
        {
            text: this.props.t('footer.links.imprint'),
            link: "/imprint"
        }
    ]

    skills = [
        {
            title: this.props.t("skills.architecture"),
            percentage: 70,
            color: "#afafaf"
        },
        {
            title: this.props.t("skills.frontend"),
            percentage: 95,
            color: "#a22206"
        },
        {
            title: this.props.t("skills.backend"),
            percentage: 80,
            color: "#afafaf"
        },
        {
            title: this.props.t("skills.testing"),
            percentage: 95,
            color: "#a22206"
        },
        {
            title: this.props.t("skills.dbs"),
            percentage: 70,
            color: "#afafaf"
        },
        {
            title: this.props.t("skills.teamwork"),
            percentage: 99,
            color: "#a22206"
        }
    ]

    projects = [
        {
            title: this.props.t("projects.title1"),
            domain: this.props.t("projects.domain1"),
            description: this.props.t("projects.desc1")
        },
        {
            title: this.props.t("projects.title2"),
            domain: this.props.t("projects.domain2"),
            description: this.props.t("projects.desc2")
        },
        {
            title: this.props.t("projects.title3"),
            domain: this.props.t("projects.domain3"),
            description: this.props.t("projects.desc3")
        },
        {
            title: this.props.t("projects.title4"),
            domain: this.props.t("projects.domain4"),
            description: this.props.t("projects.desc4")
        },
        {
            title: this.props.t("projects.title5"),
            domain: this.props.t("projects.domain5"),
            description: this.props.t("projects.desc5")
        }
    ];

    scrollToTop = (event) => {
        event.preventDefault();
        document.querySelector("#about").scrollIntoView({ behavior: "smooth" });
    }

    render() {
        const { t } = this.props;
        const { showNavigation, showTopButton, loading } = this.state;

        if (loading) {
            return <SpinnerContainer />;
        }

        return (
            <Container>
                <Head hreflang>
                    <title>{t('page.title')}</title>
                    <meta name="description" content={t('page.description')} />
                    <meta name="keywords" content={t('page.keywords')} />
                </Head>
                { showNavigation ? <Menu menuLinks={this.links} smallLogo={smallLogo} /> : null }
                <Header>
                    <Logo logo={logo} />
                    <WelcomeContainer>
                        <Welcome>
                            <Avatar avatarImage={avatarImage} />
                            <H3>{t('title.welcome')}</H3>
                            <P>
                                {t('content.welcome')}
                            </P>
                        </Welcome>
                    </WelcomeContainer>
                </Header>
                <Section
                    id={"about"}
                    withPicture
                    backgroundColor={"#807B85"}
                    backgroundImage={who}
                    title={t('title.about')}
                    text={t('content.about')}
                />
                <Section 
                    id={"skills"}
                    primary
                    withPicture
                    backgroundColor={"#807B85"}
                    backgroundImage={what}
                    title={t('title.skills')}
                    text={t('content.skills')}
                >
                    <Skills title={t('skills.title')} skills={this.skills} />
                </Section>
                {/* <Section
                    id={"customers"}
                    backgroundColor={"aliceblue"}
                    title={t('title.customers')}
                    text={t('content.customers')}
                /> */}
                <Section
                    id="projects"
                    primary
                    withPicture
                    backgroundColor={"#807B85"}
                    backgroundImage={how}
                    title={t('title.projects')}
                    text={t('content.projects')}
                >
                    <Projects projects={this.projects} />
                </Section>
                <Section
                    id="contact"
                    primary
                    primaryContent={<ContactIcon>
                        <Img alt="mail" src={mail} />
                        <MailLink href="mailto:info@narmocode.de" target="_blank">info@narmocode.de</MailLink>
                    </ContactIcon>}
                    secondary
                    withPicture
                    backgroundColor={"#807B85"}
                    backgroundImage={contact}
                    title={t('title.contact')}
                    text={t('content.contact')}
                >
                    <IconsContainer>
                        <Icons>
                            <Icon>
                                <a href="https://www.linkedin.com/company/narmocode">
                                    <Img title="LinkedIn" alt="linkedin" src={linkedin} />
                                </a>
                            </Icon>
                            <Icon>
                                <a href="https://www.xing.com/xbp/pages/narmocode">
                                    <Img title="Xing" alt="xing" src={xing} />
                                </a>
                            </Icon>
                            <Icon>
                                <a href="http://twitter.com/narmocode">
                                    <Img title="Twitter" alt="twitter" src={twitter} />
                                </a>
                            </Icon>
                            <Icon>
                                <a href="https://www.facebook.com/Narmocode-965000030351890">
                                    <Img title="Facebook" alt="facebook" src={facebook} />
                                </a>
                            </Icon>
                        </Icons>
                    </IconsContainer>
                </Section>
                <Footer footerLinks={this.footerLinks} />
                { showTopButton ? <GoToTop><span onClick={this.scrollToTop} title={t("top.button.tooltip")}><Img alt="github" src={arrow} /></span></GoToTop> : null }
            </Container>
        );
    }
}

export default translate()(App);
