import React from "react";
import styled, { css } from "styled-components";

const StyledSection = styled.section`
    padding: 20px 50px;
    text-align: justify;
    background-color: ${props => props.backgroundColor}

    ${props => props.backgroundColor && css`
        background-color: ${props.backgroundColor};
    `}

    ${props => props.backgroundImage && css`
        background: url(${props.backgroundImage}) no-repeat center center;
        background-size: cover;
    `}
    
    ${props => props.textColor && css`
        color: ${props.textColor};
    `}

    @media (max-width: 640px) {
        text-align: start;
    }
`

const WithPicSection = styled.section`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: justify;

    @media (max-width: 640px) {
        text-align: start;
    }
`

const Container = styled.div`
    ${props => props.backgroundImage && css`
        max-width: 100%;
        min-height: 400px;
        background: url(${props.backgroundImage}) no-repeat center center fixed;
        background-size: cover;

        @supports (-webkit-overflow-scrolling: touch) {
            background-attachment: initial;
        }
    `}

    ${props => props.backgroundColor && css`
        background-color: ${props.backgroundColor};
    `}
`

const H1 = styled.h1`
    text-align: center;
    margin-bottom: 3rem;
`

const TextContainer = styled.div`
    margin: auto;
    padding: 0 50px 50px;
    max-width: 800px;

    @media (max-width: 640px) {
        max-width: 600px;
    }

    @media (max-width: 400px) {
        padding: 0 20px 50px;
    }
`

const Section = ({ backgroundColor, textColor, backgroundImage, title, text, children , primary = false, primaryContent, secondary = false, id, withPicture }) => {
    if (withPicture) {
        return (
            <WithPicSection id={id} color={textColor}>
                <TextContainer>
                    <H1>{title}</H1>
                    <p>{text}</p> 
                    { primary ? primaryContent ? primaryContent : children : null }   
                </TextContainer>
                <Container backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
                    { secondary ? children : null }
                </Container>
            </WithPicSection>
        );
    } else {
        return (
            <StyledSection id={id} backgroundColor={backgroundColor} backgroundImage={backgroundImage} textColor={textColor}>
                <H1>{title}</H1>
                <p>{text}</p>
                {children}
            </StyledSection>
        );
    }
    
}

export default Section;